import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import GatsbyBackgroundImage from 'gatsby-background-image'

export default ({ bgImagePath, tint, children, ...props }) => {
  const data = useStaticQuery(graphql`
		query {
			allFile(filter: {relativePath: {regex: "/backgrounds/"}}) {
				nodes {
					relativePath
					childImageSharp {
						fluid (maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
  `);
	const chosenBgImageNode = data.allFile.nodes.find(node => {
		return node.relativePath.includes(bgImagePath);
	});
	let bgImage;
	if (!tint) {
		bgImage = chosenBgImageNode.childImageSharp.fluid
	} else {
		bgImage = [
			`linear-gradient(${tint}, ${tint})`,
			chosenBgImageNode.childImageSharp.fluid
		]
	}
  return (
		<GatsbyBackgroundImage {...props} fluid={bgImage}>
			{children}
		</GatsbyBackgroundImage>
  )
}