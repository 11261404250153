import { Link } from "gatsby"
import React from "react"

export default () => (
	<footer>
  	<div className="main">
			<div className="container">
				<div className="row">
					<div className="col">
						<p>
							<strong>Birds of Beauty Parlour</strong><br/>
							21 Westdale Crescent<br/>
							Carlton<br/>
							NG4 3JP<br/>
							Nottingham<br/>
							Directions<br/>
						</p>
						<p>
							<strong>tel:</strong> <a href="tel:07812 187531">07812 187531</a><br/>
							<strong>email:</strong> <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>
						</p>
						<div className="socials">
							<a href="https://www.facebook.com/Bobp21/"><i className="fab fa-facebook" aria-label="Visit Birds of Beauty on Facebook"></i></a>
							<a href="https://www.instagram.com/birdsofbeautyparlour/"><i className="fab fa-instagram" aria-label="Visit Birds of Beauty on Instagram"></i></a>
						</div>
					</div>
					<div className="col">
						<h2>Opening times</h2>
						<ul>
							<li>Monday: Closed</li>
							<li>Tuesday: 1pm to 8pm</li>
							<li>Wednesday: Closed</li>
							<li>Thursday: 10.15am to 6.15pm</li>
							<li>Friday: 9.30am to 6pm</li>
							<li>Saturday: 9.30am to 2.30pm</li>
							<li>Sunday: Closed</li>
						</ul>
					</div>
					<div className="col newsletter">
						<h2>Get this awesome guide</h2>
						<p>Sign up to stay updated with the latest news, offers and discounts by email, and get a free guide to the top 9 eyebrow treatments.</p>
						<form id="newsletter-signup" name="newsletter-signup" action="/newsletter-success" method="POST" data-netlify="true">
							<p>
								<label className="sr-only" htmlFor="email">Your email address</label>
								<input id="email" name="email" type="email" placeholder="Enter your email address"/>
							</p>
							<input name="form-name" value="newsletter-signup" type="hidden"/>
							<button className="button cta" type="submit">Sign up</button>
						</form>
					</div>
					<div className="col nav">
						<ul>
							<li><Link to="/treatments">Treatments</Link></li>
							<li><Link to="/book">Book</Link></li>
							<li><Link to="/shop">Shop</Link></li>
							<li><Link to="/gallery">Gallery</Link></li>
							<li><Link to="/blog">Blog</Link></li>
							<li><a href="https://www.robovoucher.com/buy/list/UK4GCS-2231">Gift vouchers</a></li>
            	<li><a href="https://www.bbc.co.uk">Memberships</a></li>
							<li><Link to="/about">About</Link></li>
            	<li><Link to="/contact">Contact</Link></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div className="sub">
			<div className="container">
				<div className="row">
					<div className="col legals">
						<ul>
							<li><Link to="/policies/terms-conditions">Terms and conditions</Link></li>
							<li><Link to="/policies/privacy">Privacy and cookies</Link></li>
							<li><Link to="/policies/booking-cancellation">Booking and cancellation policy</Link></li>
						</ul>
					</div>
					<div className="col">
						Copyright &copy; Birds of Beauty Parlour {new Date().getFullYear()}
					</div>
					<div className="col credit">
						Website by Ed Hood
					</div>
				</div>
			</div>
		</div>
  </footer>
)