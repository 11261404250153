import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { CSSTransition } from 'react-transition-group';

export default () => {
	const [cookieMessageVisible, setcookieMessageVisible] = useState(false);
	useEffect(() => {
		if (document.cookie.indexOf('acceptCookies=1') === -1) {
			setcookieMessageVisible(true);	
		}
	}, []);
	const handleCookieButton = () => {
		setcookieMessageVisible(false);	
		document.cookie = "acceptCookies=1;path=/;max-age=31536000";
	}
	return (
		<CSSTransition in={cookieMessageVisible} mountOnEnter unmountOnExit timeout={200}>
			<div className="cookie-message" key={1}>
				<p>This website uses cookies. By using this website you agree that we can store and access cookies on your device. <Link to="/policies/privacy">Read our privacy policy.</Link></p>
				<button className="button cta" onClick={handleCookieButton}>Accept cookies</button>
			</div>
		</CSSTransition>
	)
}