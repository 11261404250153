import React from "react"

import Nav from "./Nav"
import Footer from "./Footer"
import ScrollUp from "./Scrollup"
import Cookies from "./Cookies"

export default ({ children }) => (
  <>
    <Nav />
    <main>{children}</main>
    <Footer />
    <ScrollUp />
    <Cookies />
  </>
)