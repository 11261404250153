import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"

export default () => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 600) {
				setScrolled(true);
			} else if (window.scrollY < 500) {
				setScrolled(false);
			}
		});
	});
  return (
    <nav className={scrolled ? 'scrolled' : null}>
      <div className="container">
        <div className="brand">
          <div className="item-block">
            <Link to="/treatments" className="item">
              <i className="fas fa-spa"></i>
              <span>Treatments</span>
            </Link>
            <Link to="/book" className="item">
              <i className="fas fa-calendar-check"></i>
              <span>Book</span>
            </Link>
          </div>
          <Link className="site-logo" to="/">
            <img src="/bob_coral.png" alt="Homepage"/>
          </Link>
          <div className="item-block">
            <Link to="/shop" className="item">
              <i className="fas fa-store"></i>
              <span>Shop</span>
            </Link>
            <Link to="/basket" className="item">
              <i className="fas fa-shopping-bag"></i>
              <span>Basket</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container fade">
        <div className="overflow">
          <div className="main">
            <Link to="/">
              Home
            </Link>
            <Link to="/gallery">
              Gallery
            </Link>
            <Link to="/blog">
              Blog
            </Link>
            <a href="https://www.robovoucher.com/buy/list/UK4GCS-2231">
              Gift vouchers
            </a>
            <a href="https://www.bbc.co.uk">
              Memberships
            </a>
            <Link to="/about">
              About
            </Link>
            <Link to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}