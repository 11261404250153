import React, { useState, useEffect } from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'

export default () => {
	const [scrolled, setScrolled] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 800) {
				setScrolled(true);
			} else if (window.scrollY < 700) {
				setScrolled(false);
			}
		});
	});
	return (
		<a href="#___gatsby" onClick={() => scrollTo('#___gatsby')}>
			<div className={scrolled ? 'scrollToTop show' : 'scrollToTop'}>
				<i className="fas fa-arrow-up" aria-hidden="true"></i>
				<span className="sr-only">Scroll to top of page</span>
			</div>
		</a>
	)
}